import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { WebRequestFactory } from "src/app/http/web.request.factory";
import { ConfigurationService } from "src/app/settings/types/configuration.service";
import { FsgBuilding } from "src/app/dto/fsg/fsg-building";
import { Area } from "src/app/dto/items/area";
import { ChangeItem } from "src/app/dto/items/change-item";
import { CircleArea } from "src/app/dto/items/circle-area";
import { Incident } from "src/app/dto/items/incident";
import { Kml } from "src/app/dto/items/kml";
import { IMAGE_STATUS, Overlay, OVERLAY_TYPE } from "src/app/dto/items/overlay";
import { Poi } from "src/app/dto/items/poi";
import { PolygonArea } from "src/app/dto/items/polygon-area";
import { SHAPE_TYPE } from "src/app/dto/items/shape-type";
import { AreaType } from "src/app/dto/items/types/area-type";
import { PoiType } from "src/app/dto/items/types/poi-type";
import { Floor } from "src/app/dto/map/floor";
import { WGSPoint } from "src/app/dto/map/location";
import { CloneFactory } from "src/app/dto/net/clone-factory";
import { DTOArray } from "src/app/dto/net/dto-array";
import { UploadedFile } from "src/app/dto/net/uploaded-file";
import { Resource } from "src/app/dto/resources/resource";
import { LocaleMap } from "src/app/global/constants/text/text-interface";
import { TextProvider } from "src/app/global/constants/text/text-provider";
import { MainService } from "src/app/global/main.service";
import { MESSAGE_TYPE } from "src/app/global/messaging/messages";
import { MessagingService } from "src/app/global/messaging/messaging.service";
import { ResourceService } from "src/app/settings/resource/resource.service";
import { FsgService } from "../incident-tools/fsg/fsg.service";
import { Bounds } from "src/app/dto/map/bounds";

declare const geoXML3: any;

@Injectable({
	providedIn: "root"
})
export class MapItemsService {
	public kmlParser: any;
	public kmzParser: any;

	public isLive?: boolean = true;
	public isBuffered?: boolean;
	public getKmlDataUrl = new Map<number, string>();
	public readonly itemsLoaded$ = new Subject<void>();
	public readonly kmlLoaded$ = new Subject<Kml[]>();
	public Areas: Array<Area> = new Array();
	/**
	 * Array of all the POIs for the current mission
	 * @property POIs
	 * @type POI[]
	 */
	public POIs: Array<Poi> = new Array<Poi>();
	/**
	 * Array of KMLs for this mission and general
	 * @property KMLs
	 * @type Kml[]
	 */
	public KMLs: Array<Kml> = new Array();
	public missionKMLs: Array<Kml> = new Array();

	public Overlays: Array<Overlay> = new Array();

	// idCounter begins at 2 so there's no conflict with attachments that have an id_object value of -1 (since new poi/areas use -(idCounter))
	public idCounter: number = 2;
	public unsavedPois: Array<Poi> = new Array<Poi>();
	public mission: Incident | null = null;
	public arrayChanges: Array<ChangeItem> = new Array(); //changes in current mission

	public areaMarker_visible: boolean = true;
	public updateSectorName$ = new Subject<Area>();

	public movingOverlay: Overlay | undefined;

	public $poiAttachmentChange = new Subject<POI_ATTACHMENT_CHANGE>();

	public isHiddenSubject = new BehaviorSubject<boolean>(false);
	public isHidden$ = this.isHiddenSubject.asObservable();

	private attachments: Array<UploadedFile> = new Array();
	private POIattachmentList: Array<Array<UploadedFile>> = new Array();

	private readonly wreq: WebRequestFactory;
	private readonly main: MainService;
	private readonly conf: ConfigurationService;
	private readonly mssg: MessagingService;
	private readonly res: ResourceService;
	private readonly fsg: FsgService;
	private readonly pattern: any;
	private readonly text: () => LocaleMap;
	private visibilityFilter: Array<FilterGroup> = [];

	/**
	 * Array of all the areas for the current mission
	 * @property Areas
	 * @type Area[]
	 */
	private AreasDB: Array<Area> = new Array();

	//Array used for OFFLINE (POIs are created with Id decremeted -1 and saved in this array until they are saved to DB)
	private POIsDB: Array<Poi> = new Array<Poi>();

	private unsavedAreas: Array<Area> = new Array();

	private missionPerimeter: Area | undefined;

	private isLoadPending: boolean = false;

	constructor(textProv: TextProvider, wreq: WebRequestFactory, main: MainService, conf: ConfigurationService, mssg: MessagingService, res: ResourceService, fsg: FsgService) {
		this.wreq = wreq;
		this.main = main;
		this.conf = conf;
		this.mssg = mssg;
		this.res = res;
		this.fsg = fsg;
		this.text = textProv.getStringMap;

		this.mssg.registerListener(MESSAGE_TYPE.UNLOAD_MISSION, this.clearAll);

		mssg.registerListener(MESSAGE_TYPE.PLAY_MODE_CHANGE, (params: { liveMode: boolean; buffered: boolean }) => {
			this.isLive = params.liveMode;
			this.isBuffered = params.buffered;
		});
	}

	public readonly load: Function = async (success: any) => {
		if (!success || this.isLoadPending) return false;
		this.mission = this.main.getCurrentIncident()!;
		if (this.mission !== null) {
			this.isLoadPending = true;
			this.missionKMLs = this.KMLs.filter(this.filterKml);
			success = await this.updateAreas(success);
			this.missionPerimeter = this.Areas.find((e) => e.id === this.mission?.perimeter);
			success = await this.updatePois(success);
			if (success) success = await this.updateOverlays(this.mission && this.mission.closed);
			this.itemsLoaded$.next();
			this.kmlLoaded$.next(this.missionKMLs);
			this.isLoadPending = false;
			return success;
		} else return Promise.resolve(success);
	};

	public readonly confLoad: Function = (success: any) => {
		return this.updateKmls(success).then(() => {
			if (this.mission) this.missionKMLs = this.KMLs.filter(this.filterKml);
			this.kmlLoaded$.next(this.missionKMLs);
		});
	};

	public readonly unload: Function = () => {
		this.Areas.length = 0;
		this.AreasDB.length = 0;
		this.POIs.length = 0;
		this.POIsDB.length = 0;
		this.KMLs.length = 0;
		this.missionKMLs.length = 0;
		this.Overlays.length = 0;
		this.unsavedPois.length = 0;
		this.unsavedAreas.length = 0;
	};
	/**
	 * Requests all the Areas data for the current mission to the server. Updates the current array without replacing the existing
	 * objects for the new
	 * @method updateAreas
	 * @return {Area[]}
	 */
	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	public readonly updateAreas = async (success: any): Promise<boolean> => {
		let mis = this;
		if (success) {
			let mission = mis.main.getCurrentIncident();
			const id_mission = mission ? mission.id : -1;
			const jsonArray: Array<string> = await mis.wreq.getAllMissionAreas(id_mission, mission && mission.closed);

			if (!jsonArray) return false;

			if (!mis.main.editing) {
				if (!jsonArray) return false;
				const areaArr: Array<Area> = mis.castJSONAreaArray(jsonArray);

				areaArr.length && areaArr.forEach(this.addTypeToArea as any);
				mis.Areas = mis.AreasDB = areaArr;

				for (var n = 0; n < mis.unsavedAreas.length; n++) {
					if (mis.unsavedAreas[n].deleted) mis.deleteArea(mis.unsavedAreas[n], true);
					else mis.saveArea(mis.unsavedAreas[n], true);
				}

				mis.unsavedAreas = new Array();
			}
			return true; //setupAttachments();
		}
		return false;
	};

	public readonly getAreaUpdate: Function = async (area_id: number) => {
		if (this.missionPerimeter && this.missionPerimeter.id === area_id) this.refreshPrePlannedInfo();
		const jsonArray = await this.wreq.getAreaById(area_id);
		if (jsonArray) {
			let areaType = Area.getTypeFromJson(jsonArray);
			let updatedArea: Area;
			switch (areaType) {
				case SHAPE_TYPE.CIRCLE:
					updatedArea = CircleArea.fromJson(jsonArray);
					break;
				case SHAPE_TYPE.POLYGON:
					updatedArea = PolygonArea.fromJson(jsonArray);
					break;
				case SHAPE_TYPE.POLYLINE:
					updatedArea = PolygonArea.fromJson(jsonArray);
					break;
				default:
					updatedArea = Area.fromJson(jsonArray);
					var areaUS2 = { ...updatedArea };
					DTOArray.FillById(this.unsavedAreas, [areaUS2], false, null);
					return false;
			}
			this.addTypeToArea(updatedArea);
			var index = this.Areas.findIndex((e) => e.id === area_id);
			if (updatedArea) {
				if (index < 0) this.Areas.push(updatedArea);
				else this.Areas[index] = updatedArea;
			}
			index = this.AreasDB.findIndex((e) => e.id === area_id);
			if (updatedArea) {
				if (index < 0) this.AreasDB.push(updatedArea);
				else this.AreasDB[index] = updatedArea;
			}
			this.mssg.fire(MESSAGE_TYPE.UPDATE_AREA, updatedArea);
			return true;
		} else return false;
	};

	/**
	 * Sends request to the webservive to save an Area to the server. Returns the Area as it was saved
	 * (if it's not able to save some part of it , it returns the area as exists on the server  )
	 * @method saveArea
	 * @param {Area} area The area to be saved.
	 * @return {Area}
	 */
	public readonly saveArea = async (area: Area, ignoreChange?: boolean): Promise<Area | undefined> => {
		if (!this.isLive) return;
		//If area has a specific mission assigned and itself has no id (it's not yet saved), add to mission when saving
		let savingFunc = area.id_incident > -1 && area.id === -1 ? this.wreq.saveIncidentArea : this.wreq.saveArea;
		const areaO = this.Areas.find((e) => e.id === area.id);
		let waitsave = areaO && areaO.id < 0;

		DTOArray.FillById(this.Areas, [area], false, null);
		this.addTypeToArea(area);
		let oldAreaValue;
		if (area.id > -1) {
			let areaDB = { ...area };
			for (var c = 0; c < this.AreasDB.length; c++) {
				if (this.AreasDB[c].id == area.id) {
					oldAreaValue = this.AreasDB.splice(c, 1)[0];
					break;
				}
			}
			this.AreasDB.push(areaDB);
		}

		if (!ignoreChange) {
			var newChange = new ChangeItem(area.id, "area", oldAreaValue);
			if (!this.changeExists(newChange)) this.arrayChanges.push(newChange);
			//arrayChanges.push({ type: "area", elementId: area.id, oldValue: oldAreaValue });
			this.mssg.fire(MESSAGE_TYPE.HISTORY_CHANGE);
		}
		if (waitsave) {
			let areaUS = { ...area };
			DTOArray.FillById(this.unsavedAreas, [areaUS], false, null);
			return Promise.resolve(areaUS);
		}
		const areaJson = await savingFunc(area, area.shapeType);
		var oldId = area.id;
		if (areaJson) {
			if (this.conf.configuration.id_current_incident !== -1 && oldId === -1) this.main.setSnackbar(this.text().AREA_SAVED_NEW);
			else this.conf.configuration.id_current_incident !== -1 && this.main.setSnackbar(this.text().AREA_SAVED);
			let areaType = Area.getTypeFromJson(areaJson);
			let areaSaved: Area;

			switch (areaType) {
				case SHAPE_TYPE.CIRCLE:
					areaSaved = CircleArea.fromJson(areaJson);
					break;
				case SHAPE_TYPE.POLYGON:
					areaSaved = PolygonArea.fromJson(areaJson);
					break;
				case SHAPE_TYPE.POLYLINE:
					areaSaved = PolygonArea.fromJson(areaJson);
					break;
				default:
					areaSaved = Area.fromJson(areaJson);
					var areaUS2 = { ...areaSaved };
					DTOArray.FillById(this.unsavedAreas, [areaUS2], false, null);
					return;
			}
			let areax = this.Areas.find((e) => e.id === oldId);
			if (oldId < 0) {
				if (areax) {
					areax.id = areaSaved.id;
					if (areax.name == "saving area...") areax.name = areaSaved.name;
				}
				let adb = this.AreasDB.find((e) => e.id === oldId);
				if (adb) adb.id = areaSaved.id;
				const change = this.arrayChanges.find((e) => e.elementId == oldId && e.type === "area");
				if (change) change.elementId = areaSaved.id;
			} else {
				for (var k = 0; k < this.arrayChanges.length; k++) {
					if (this.arrayChanges[k].elementId == oldId && this.arrayChanges[k].type == "area" && this.arrayChanges[k].oldValue && this.arrayChanges[k].oldValue!.name == "saving area...") this.arrayChanges[k].oldValue!.name = areaSaved.name;
				}
			}
			var usa = DTOArray.FindById(this.unsavedAreas, oldId);
			if (usa) {
				if (areax) {
					if (areax.deleted) this.deleteArea(areax, true);
					else this.saveArea(areax, true);
				}
				DTOArray.DeleteById(this.unsavedAreas, oldId);
			}
			this.updateSectorName$.next(areaSaved);
			this.addTypeToArea(areaSaved);
			return areaSaved;
		} else {
			var areaUS2 = { ...area };
			DTOArray.FillById(this.unsavedAreas, [areaUS2], false, null);
			return;
		}
	};

	/**
	 * Sends request to server to delete an area.
	 * @method deleteArea
	 * @param {Area} area The area to delete
	 * @return {Boolean} True if it was deleted, false otherwise.
	 */
	public readonly deleteArea: Function = async (area: Area, ignoreChange: boolean) => {
		if (!this.isLive) return false;
		area.deleted = true;
		this.pattern && this.pattern.removeAreaFill(area);
		if (!ignoreChange) {
			var oldAreaValue;
			var areaDB = { ...area };
			for (var c = 0; c < this.AreasDB.length; c++) {
				if (this.AreasDB[c].id == area.id) {
					oldAreaValue = this.AreasDB.splice(c, 1)[0];
					break;
				}
			}
			this.AreasDB.push(areaDB);
			var newChange = new ChangeItem(area.id, "area", oldAreaValue);
			if (!this.changeExists(newChange)) this.arrayChanges.push(newChange);
			//arrayChanges.push({ type: "area", elementId: area.id, oldValue: oldAreaValue });
			this.mssg.fire(MESSAGE_TYPE.HISTORY_CHANGE);
		}
		if (area.id < 0) {
			let areaUS = { ...area };
			DTOArray.FillById(this.unsavedAreas, [areaUS], false, null);
			return Promise.resolve(true);
		}
		var oldId = area.id;
		var oldname = area.name;
		const success = await this.wreq.deleteArea(area.id, area.id_incident);
		if (success) {
			this.main.setSnackbar(this.text().AREA_DELETED);
			for (var k = 0; k < this.arrayChanges.length; k++) {
				if (this.arrayChanges[k].elementId == oldId && this.arrayChanges[k].type == "area" && this.arrayChanges[k].oldValue && this.arrayChanges[k].oldValue!.name == "saving area...") this.arrayChanges[k].oldValue!.name = oldname;
			}
			return true;
		} else {
			let areaUS2 = { ...area };
			DTOArray.FillById(this.unsavedAreas, [areaUS2], false, null);
			return false;
		}
	};

	public readonly deleteAreaForever: Function = async (area: Area) => {
		if (!this.isLive) return false;
		const success = await this.wreq.deleteAreaForever(area.id, area.id_incident);
		if (success) {
			DTOArray.DeleteById(this.Areas, area.id);
			return true;
		} else return false;
	};

	public isPoiInsideIncidentPerimeter(poi: Poi): boolean {
		if (!poi.is_global) return true;
		const ans = this.areCoordsInsideArea(poi.position.latitude, poi.position.longitude, this.missionPerimeter);
		if (ans) return ans;
		else {
			if (this.missionPerimeter && this.missionPerimeter.shapeType !== SHAPE_TYPE.POLYGON) {
				return this.getDistanceFromLatLonInKm(poi.position.latitude, poi.position.longitude, (this.missionPerimeter as CircleArea).center.latitude, (this.missionPerimeter as CircleArea).center.longitude) < (this.missionPerimeter as CircleArea).radius;
			}
			return false;
		}
	}

	public readonly overlayFilter = (plane: Overlay): boolean => {
		return this.drawingFilter(plane) || this.schematicFilter(plane);
	};

	public readonly drawingFilter = (plane: Overlay): boolean => {
		return plane.datatype === OVERLAY_TYPE.DRAWING && !!this.main.getCurrentIncident() && this.main.getCurrentIncident()!.id === plane.id_incident;
	};

	public readonly schematicFilter = (plane: Overlay): boolean => {
		return plane.datatype === OVERLAY_TYPE.SCHEMATIC && this.isOverlayInsideMissionPerimeter(plane);
	};

	public readonly isOverlayInsideMissionPerimeter: Function = (plane: Overlay) => {
		let mission = this.main.getCurrentIncident();
		if (!mission) return;
		this.missionPerimeter = this.Areas.find((e) => e.id === mission?.perimeter);
		if (!this.missionPerimeter) return;
		// approximate boundaries, not calculating mercator projection distortion,
		// should be accurate enough for anywhere far from the arctic/antarctic zones
		if (this.missionPerimeter.shapeType === SHAPE_TYPE.CIRCLE)
			return (
				this.getDistanceFromLatLonInKm(plane.sw_lat, plane.sw_lon, (this.missionPerimeter as CircleArea).center.latitude, (this.missionPerimeter as CircleArea).center.longitude) < (this.missionPerimeter as CircleArea).radius ||
				this.getDistanceFromLatLonInKm(plane.sw_lat, plane.ne_lon, (this.missionPerimeter as CircleArea).center.latitude, (this.missionPerimeter as CircleArea).center.longitude) < (this.missionPerimeter as CircleArea).radius ||
				this.getDistanceFromLatLonInKm(plane.ne_lat, plane.sw_lon, (this.missionPerimeter as CircleArea).center.latitude, (this.missionPerimeter as CircleArea).center.longitude) < (this.missionPerimeter as CircleArea).radius ||
				this.getDistanceFromLatLonInKm(plane.ne_lat, plane.ne_lon, (this.missionPerimeter as CircleArea).center.latitude, (this.missionPerimeter as CircleArea).center.longitude) < (this.missionPerimeter as CircleArea).radius
			);
		else {
			var maxLat = -9999,
				minLat = 9999,
				maxLng = -9999,
				minLng = 9999;
			for (var n = 0; n < (this.missionPerimeter as PolygonArea).vertices.length; n++) {
				if ((this.missionPerimeter as PolygonArea).vertices[n].latitude > maxLat) maxLat = (this.missionPerimeter as PolygonArea).vertices[n].latitude;
				if ((this.missionPerimeter as PolygonArea).vertices[n].latitude < minLat) minLat = (this.missionPerimeter as PolygonArea).vertices[n].latitude;
				if ((this.missionPerimeter as PolygonArea).vertices[n].longitude > maxLng) maxLng = (this.missionPerimeter as PolygonArea).vertices[n].longitude;
				if ((this.missionPerimeter as PolygonArea).vertices[n].longitude < minLng) minLng = (this.missionPerimeter as PolygonArea).vertices[n].longitude;
			}
			return (
				(plane.ne_lat < maxLat && plane.ne_lat > minLat && plane.ne_lon < maxLng && plane.ne_lon > minLng) || // plane NE corner inside mission perimeter
				(plane.ne_lat < maxLat && plane.ne_lat > minLat && plane.sw_lon < maxLng && plane.sw_lon > minLng) || // plane NW corner inside mission perimeter
				(plane.sw_lat < maxLat && plane.sw_lat > minLat && plane.sw_lon < maxLng && plane.sw_lon > minLng) || // plane SW corner inside mission perimeter
				(plane.sw_lat < maxLat && plane.sw_lat > minLat && plane.ne_lon < maxLng && plane.ne_lon > minLng) || // plane SE corner inside mission perimeter
				(maxLat < plane.ne_lat && maxLat > plane.sw_lat && maxLng < plane.ne_lon && maxLng > plane.sw_lon) || // mission NE corner inside plane perimeter
				(maxLat < plane.ne_lat && maxLat > plane.sw_lat && minLng < plane.ne_lon && minLng > plane.sw_lon) || // mission NW corner inside plane perimeter
				(minLat < plane.ne_lat && minLat > plane.sw_lat && minLng < plane.ne_lon && minLng > plane.sw_lon) || // mission SW corner inside plane perimeter
				(minLat < plane.ne_lat && minLat > plane.sw_lat && maxLng < plane.ne_lon && maxLng > plane.sw_lon) || // mission SE corner inside plane perimeter
				(plane.ne_lat < maxLat && plane.sw_lat > minLat && plane.ne_lon > maxLng && plane.sw_lon < minLng) || // extreme position: plane wider than perimeter
				(plane.ne_lat > maxLat && plane.sw_lat < minLat && plane.ne_lon < maxLng && plane.sw_lon > minLng)
			); // extreme position: plane taller than perimeter
		}
	};

	/**
	 * Requests all the POIs data for the current mission to the server.Updates the current array without replacing the existing
	 * objects for the new
	 * @method updatePois
	 * @return {POI[]}
	 */
	public readonly updatePois: Function = async (success: any) => {
		if (success) {
			let mission = this.main.getCurrentIncident();
			const id_mission = mission ? mission.id : -1;
			const jsonArray = await this.wreq.getAllMissionPois(id_mission, mission && mission.closed);
			let newPoisRecv = true; //jsonArray.length !== this.POIs.length;
			if (!this.main.editing) {
				if (!jsonArray) return false;
				DTOArray.UpdateFromJsonArray(this.POIs, jsonArray, Poi, this.addTypeToPoi);
				//this.setupAttachments();
				this.POIsDB = new Array<Poi>();
				DTOArray.UpdateFromJsonArray(this.POIsDB, jsonArray, Poi, this.addTypeToPoi);
				for (let n = 0; n < this.unsavedPois.length; n++) {
					if (this.unsavedPois[n].deleted) this.deletePoi(this.unsavedPois[n], true);
					else
						this.savePoi(this.unsavedPois[n], true)
							.then((val: any) => {
								this.mssg.fire(MESSAGE_TYPE.UPLOAD_UNSAVED_POI, val);
							})
							.catch(function (error: any) {
								console.error(error);
							});
				}

				this.unsavedPois = new Array<Poi>();
			}
			return newPoisRecv ? await this.setupAttachments() : true;
		}
	};

	public async getPoiUpdate(poi_id: number): Promise<void> {
		const jsonArray = await this.wreq.getPoiById(poi_id);
		if (jsonArray) {
			const updatedPoi = Poi.fromJson(jsonArray);
			this.addTypeToPoi(updatedPoi);
			this.mission = this.main.getCurrentIncident()!;
			const isInsidePerimeter = this.isPoiInsideIncidentPerimeter(updatedPoi);
			if (!isInsidePerimeter || (!updatedPoi.is_global && this.mission && updatedPoi.id_incident !== this.mission.id)) return;

			let index = this.POIs.findIndex((e) => e.id === poi_id);
			if (index < 0) this.POIs.push(updatedPoi);
			else {
				if (
					this.POIs[index].name !== updatedPoi.name ||
					this.POIs[index].position.latitude.toFixed(5) !== updatedPoi.position.latitude.toFixed(5) ||
					this.POIs[index].position.longitude.toFixed(5) !== updatedPoi.position.longitude.toFixed(5) ||
					this.POIs[index].description !== updatedPoi.description ||
					this.POIs[index].attachment_id != updatedPoi.attachment_id ||
					this.POIs[index].status !== updatedPoi.status ||
					this.POIs[index].deleted != updatedPoi.deleted ||
					this.POIs[index].custom_colour !== updatedPoi.custom_colour
				) {
					updatedPoi.__markerAttachment = this.POIs[index].__markerAttachment;
					const oldAttachs = this.POIs[index].attachmentList.map((e) => {
						return {
							id: e.id,
							__unseen: e.__unseen
						};
					});
					this.POIs[index] = updatedPoi;
					await this.setupAttachments();
					updatedPoi.attachmentList = this.POIs[index].attachmentList;
					if (updatedPoi.attachmentList)
						updatedPoi.attachmentList.forEach((attach) => {
							if (!oldAttachs.find((e) => e.id === attach.id && !e.__unseen)) attach.__unseen = true;
						});
				} else {
					const oldAttachs = this.POIs[index].attachmentList.map((e) => {
						return {
							id: e.id,
							__unseen: e.__unseen
						};
					});
					await this.setupAttachments();
					updatedPoi.attachmentList = this.POIs[index].attachmentList;
					if (updatedPoi.attachmentList)
						updatedPoi.attachmentList.forEach((attach) => {
							if (!oldAttachs.find((e) => e.id === attach.id && !e.__unseen)) attach.__unseen = true;
						});
					if (oldAttachs.length !== this.POIs[index].attachmentList?.length || oldAttachs.find((e) => !this.POIs[index].attachmentList.find((j) => j.id === e.id))) this.mssg.fire(MESSAGE_TYPE.UPDATE_POI, updatedPoi);
					return;
				}
			}
			let poiDB = new Poi(-1, -1, -1, -1, -1);
			CloneFactory.cloneProperties(poiDB, updatedPoi);
			index = this.POIsDB.findIndex((e) => e.id === poi_id);
			if (index < 0) this.POIsDB.push(poiDB);
			else this.POIsDB[index] = poiDB;

			this.mssg.fire(MESSAGE_TYPE.UPDATE_POI, updatedPoi);
		}
	}

	/**
	 * Adds reference to type object to the area object as __typeObj property
	 *
	 * @method addTypeToArea
	 * @param {Area} area
	 */
	public readonly addTypeToArea: Function = (area: Area) => {
		area.__typeObj = this.conf.configuration.areaTypes.find((e) => e.id === area.type);
	};

	/**
	 * Adds reference to type object to the area object as __typeObj property
	 *
	 * @method addTypeToPoi
	 * @param {Poi} poi
	 */
	public readonly addTypeToPoi: Function = (poi: Poi) => {
		const type = this.conf.configuration.poiTypes.find((e) => e.id === poi.type);
		poi.__typeObj = type;
		poi.is_global = type ? type.is_global : false;
	};
	/**
	 * Sends request to the webservive to save a POI to the server. Returns the POI as it was saved
	 * (if it's not able to save it or some part of it , it returns the poi as exists on the server )
	 * @method savePoi
	 * @param {POI} poi The poi to be saved.
	 * @return {POI}
	 */
	public readonly savePoi = async (poi: Poi, ignoreChange?: boolean): Promise<Poi | undefined> => {
		if (!this.isLive) return;
		const poii = this.POIs.find((e) => e.id === poi.id);
		let waitsave = poii && poii.id < 0;

		DTOArray.FillById(this.POIs, [poi], false, null);
		this.addTypeToPoi(poi);
		let oldPoiValue: Poi | null = null;
		if (poi.id > -1) {
			let poiDB = { ...poi };
			for (let c = 0; c < this.POIsDB.length; c++) {
				if (this.POIsDB[c].id == poi.id) {
					oldPoiValue = this.POIsDB.splice(c, 1)[0];
					break;
				}
			}
			this.POIsDB.push(poiDB);
		}
		if (!ignoreChange) {
			var newChange = { type: "poi", elementId: poi.id, oldValue: oldPoiValue };
			if (!this.changeExists(newChange)) this.arrayChanges.push(newChange);
			this.mssg.fire(MESSAGE_TYPE.HISTORY_CHANGE);
		}
		if (waitsave) {
			let poiUS = { ...poi };
			DTOArray.FillById(this.unsavedPois, [poiUS], false, null);
			await this.setupAttachments();
			return Promise.resolve(poiUS);
		}
		const poiJson = await this.wreq.savePoi(poi);
		var oldId = poi.id;
		if (poiJson) {
			if (oldId > -1) this.main.setSnackbar(this.text().POI_UPDATED);
			else this.main.setSnackbar(this.text().POI_SAVED);
			if (!this.isPoiInsideIncidentPerimeter(poi)) this.main.addDangerAlert(this.text().OUTSIDE_AREA_INFO(this.text().POI_1), this.text().OUTSIDE_AREA);

			const poiSaved = Poi.fromJson(poiJson);
			let poix = this.POIs.find((e) => e.id === oldId);
			if (oldId < 0) {
				if (poix) {
					poix.id = poiSaved.id;
					if (poix.name == "saving poi...") poix.name = poiSaved.name;
				}
				const poidb = this.POIsDB.find((e) => e.id === oldId);
				if (poidb) poidb.id = poiSaved.id;
				const idx = this.arrayChanges.findIndex((e) => e.elementId === -1 && e.type == "poi");
				if (idx > -1) this.arrayChanges[idx].elementId = poiSaved.id;
			}
			var usp = this.unsavedPois.find((e) => e.id === oldId);
			if (usp) {
				if (usp.deleted) this.deletePoi(poix, true);
				else if (poix) await this.savePoi(poix, true);
				DTOArray.DeleteById(this.unsavedPois, oldId);
			}
			await this.setupAttachments();
			return Promise.resolve(poi);
		} else {
			let poiUS2 = { ...poi };
			DTOArray.FillById(this.unsavedPois, [poiUS2], false, null);
			await this.setupAttachments();
			return;
		}
	};

	/**
	 * Sends request to server to delete a POI.
	 * @method deletePoi
	 * @param {POI} poi The poi to delete
	 * @return {Boolean} True if it was deleted, false otherwise.
	 */
	public readonly deletePoi: Function = async (poi: Poi, ignoreChange: boolean) => {
		if (!this.isLive) return false;
		poi.deleted = true;
		if (!ignoreChange) {
			var oldPoiValue;
			let poiDB = { ...poi };
			for (var c = 0; c < this.POIsDB.length; c++) {
				if (this.POIsDB[c].id == poi.id) {
					oldPoiValue = this.POIsDB.splice(c, 1)[0];
					break;
				}
			}
			this.POIsDB.push(poiDB);
			var newChange = new ChangeItem(poi.id, "poi", oldPoiValue);
			if (!this.changeExists(newChange)) this.arrayChanges.push(newChange);
			//arrayChanges.push({ type: "poi", elementId: poi.id, oldValue: oldPoiValue });
			this.mssg.fire(MESSAGE_TYPE.HISTORY_CHANGE);
		}
		if (poi.id < 0) {
			let poiUS = { ...poi };
			DTOArray.FillById(this.unsavedPois, [poiUS], false, null);
			return Promise.resolve(true);
		}
		var oldId = poi.id;
		var oldname = poi.name;
		const success = await this.wreq.deletePoi(poi.id, poi.id_incident);
		if (success) {
			this.main.setSnackbar(this.text().POI_DELETED);
			for (var k = 0; k < this.arrayChanges.length; k++) {
				if (this.arrayChanges[k].elementId == oldId && this.arrayChanges[k].type == "poi" && this.arrayChanges[k].oldValue && this.arrayChanges[k].oldValue!.name == "saving poi...") this.arrayChanges[k].oldValue!.name = oldname;
			}
			return true;
		} else {
			let poiUS2 = { ...poi };
			DTOArray.FillById(this.unsavedPois, [poiUS2], false, null);
			return false;
		}
	};

	/**
	 * Requests all the KMLs data for the current mission to the server.Updates the current array without replacing the existing
	 * objects for the new
	 * @method updateKmls
	 * @return {KML[]}
	 */
	public readonly updateKmls: Function = async (success: any) => {
		if (success) {
			const jsonArray = await this.wreq.getAllKmls();
			if (!jsonArray) return;
			DTOArray.UpdateFromJsonArray(this.KMLs, jsonArray, Kml);

			if (this.mission != null) this.missionKMLs = this.KMLs.filter(this.filterKml);
			this.mssg.fire(MESSAGE_TYPE.LOAD_KMLs);
			return true;
		} else return Promise.resolve(success);
	};

	public readonly getMissionKMLs = (): Kml[] => {
		return this.missionKMLs;
	};
	/**
	 * Sends request to the webservive to save a KML to the server. Returns the KML as it was saved
	 * (if it's not able to save it or some part of it , it returns the kml as exists on the server )
	 * @method saveKml
	 * @param {KML} kml The kml to be saved.
	 * @return {KML}
	 */
	public readonly saveKml: Function = async (kml: Kml) => {
		if (!this.isLive) return false;
		const kmlJson = await this.wreq.saveKml(kml);
		if (kmlJson) {
			let ans = DTOArray.AddFromJson(this.KMLs, kmlJson, Kml, null);
			return ans;
		} else return false;
	};

	/**
	 * Sends request to server to delete a KML.
	 * @method deleteKml
	 * @param {KML} kml The kml to delete
	 * @return {Boolean} True if it was deleted, false otherwise.
	 */
	public readonly deleteKml: Function = async (kml: Kml) => {
		if (!this.isLive) return false;
		const success = await this.wreq.deleteKml(kml.id);
		if (success) {
			DTOArray.DeleteById(this.KMLs, kml.id);
			return true;
		} else return false;
	};

	public readonly updateOverlays = async (isPlayback?: boolean): Promise<boolean> => {
		const rawData = await this.wreq.getAllOverlays(isPlayback, this.main.getCurrentIncident()?.id);
		let temp = new Array<Overlay>();
		DTOArray.UpdateFromJsonArray(temp, rawData, Overlay);
		temp.forEach((ol: Overlay) => {
			if (!this.Overlays.find((e) => e.id === ol.id)) {
				this.Overlays.push(ol);
			}
		});

		return true;
	};

	public readonly getOverlayUpdate = async (plane_id: number): Promise<void> => {
		const rawData = await this.wreq.getOverlayById(plane_id);
		const update = Overlay.fromJson(rawData);
		this.manageOverlayUpdate(update);
	};

	public readonly manageOverlayUpdate = async (ol: Overlay): Promise<void> => {
		const oldPlane = this.Overlays.find((e) => e.id_overlay == ol.id);
		if (oldPlane) {
			CloneFactory.cloneProperties(oldPlane, ol);
			oldPlane.__mapComponent && oldPlane.__mapComponent.setPosition(new Bounds(new WGSPoint(oldPlane.sw_lat, oldPlane.sw_lon), new WGSPoint(oldPlane.ne_lat, oldPlane.ne_lon)));

			await this.setPlaneFloors(oldPlane);
			this.mssg.fire(MESSAGE_TYPE.UPDATE_OVERLAY, oldPlane);
		} else {
			this.setPlaneFloors(ol).then(() => {
				this.Overlays.push(ol);
				this.mssg.fire(MESSAGE_TYPE.UPDATE_OVERLAY, ol);
			});
		}
	};

	public readonly saveOverlay = async (overlay: Overlay): Promise<Overlay | null> => {
		const element = this.text().OVERLAY_2;
		overlay.hasFloors = overlay.withFloors();
		let ansJson = await this.wreq.saveOverlay(overlay);
		if (!ansJson) return null;

		if (overlay.datatype === OVERLAY_TYPE.DRAWING) {
			this.main.setSnackbar(`${this.text().DRAWING}  ${this.text().HAS_BEEN_SAVED}`);
		}

		if (!this.isOverlayInsideMissionPerimeter(overlay) && overlay.datatype !== OVERLAY_TYPE.DRAWING) {
			this.main.addDangerAlert(this.text().OUTSIDE_AREA_INFO(element), this.text().OUTSIDE_AREA);
		} else {
			if (overlay.id === -1) {
				this.main.setSnackbar(this.text().OVERLAY_SAVED_NEW);
			} else {
				this.main.setSnackbar(this.text().OVERLAY_SAVED);
			}
		}

		let ans: Overlay = Overlay.fromJson(ansJson);
		const idx = this.Overlays.findIndex((e) => e.id === ans.id);
		if (idx === -1) {
			ans.floors = overlay.floors;
			ans.floors?.forEach((floor) => (floor.id_parent = ans.id));
			this.Overlays.push(ans);
			this.visibilityFilter.map((filterGroup) => {
				if (filterGroup.tag === FILTER_TYPE.DRAWINGS) filterGroup.items = this.Overlays.filter((e) => e.datatype === OVERLAY_TYPE.DRAWING);
				if (filterGroup.tag === FILTER_TYPE.PLANES) filterGroup.items = this.Overlays.filter((e) => e.datatype === OVERLAY_TYPE.SCHEMATIC);
			});
		} else {
			ans.floors = this.Overlays[idx].floors;
			CloneFactory.cloneProperties(this.Overlays[idx], ans);
		}

		CloneFactory.cloneProperties(overlay, ans);
		return ans;
	};

	public readonly saveFloor = async (floor: Floor | undefined): Promise<Floor | undefined> => {
		if (!floor) return;
		let ansJson = await this.wreq.saveFloor(floor);
		if (!ansJson) return;
		this.main.setSnackbar(this.text().OVERLAY_SAVED);
		let ans: Floor = Floor.fromJson(ansJson);
		let idx = this.Overlays.findIndex((e) => e.id === floor.id_parent);
		if (idx > -1) {
			if (!this.Overlays[idx].hasFloors) {
				this.Overlays[idx].hasFloors = true;
				this.wreq.saveOverlay(this.Overlays[idx]);
			}

			let flIdx = this.Overlays[idx].floors!.findIndex((e) => e.id === floor.id);
			if (flIdx > -1) CloneFactory.cloneProperties(this.Overlays[idx].floors![flIdx], ans);
			else this.Overlays[idx].floors ? this.Overlays[idx].floors!.push(ans) : (this.Overlays[idx].floors = [ans]);
			this.Overlays[idx].hasFloors = true;
		}
		return ans;
	};

	public readonly deleteOverlay: (param: number | Overlay) => Promise<boolean> = (param) => {
		let id: number = typeof param === "number" ? param : (param as Overlay).id;
		let idx = this.Overlays.findIndex((e) => e.id_overlay == id);
		if (idx == -1) return Promise.resolve(true);
		return this.wreq.deleteOverlay(this.Overlays[idx]).then((ans: boolean) => {
			if (ans) {
				this.deleteOverlayUpdate(id);
				this.main.setSnackbar(this.text().OVERLAY_DELETED);
				return true;
			} else return false;
		});
	};

	public readonly deleteOverlayUpdate: (id: number) => void = (id) => {
		const idx = this.Overlays.findIndex((e) => e.id === id);
		if (idx > -1) this.Overlays.splice(idx, 1);
		this.mssg.fire(MESSAGE_TYPE.DELETE_OVERLAY, id);
	};

	public readonly deleteFloor: Function = (floor: Floor) => {
		return this.wreq.deleteFloor(floor).then((ans: boolean) => {
			if (ans) {
				let flIdx: number = -1;
				let idx = this.Overlays.findIndex((e) =>
					e.floors?.find((fl, j) => {
						if (fl.id === floor.id) {
							flIdx = j;
							return true;
						} else return false;
					})
				);
				if (idx === -1 || flIdx === -1) return true;
				this.Overlays[idx].floors?.splice(flIdx, 1);
				return true;
			} else return false;
		});
	};

	public readonly loadObjectImg = async (plane: Overlay | Floor, refresh = false): Promise<void> => {
		if (plane.id_img > 0 && (refresh || plane.imgStatus === IMAGE_STATUS.MISSING)) {
			const blob = await this.wreq.getFile(plane.id_img);
			if (blob) plane.fileImg = URL.createObjectURL(blob);
			plane.imgStatus = blob ? IMAGE_STATUS.FETCHED : IMAGE_STATUS.ERROR;
		}
	};

	public readonly setPlaneFloors = async (plane: Overlay): Promise<Overlay | undefined> => {
		if (plane.datatype !== OVERLAY_TYPE.SCHEMATIC) return Promise.resolve(undefined);
		return await this.wreq.getFloors(plane.id).then((rawData: Array<string>) => {
			plane.floors = [];
			DTOArray.UpdateFromJsonArray(plane.floors, rawData, Floor);
			plane.__floorsLoaded = true;
			return plane;
		});
	};

	public readonly setFilter = (): FilterGroup[] => {
		const drawings = this.Overlays.filter((e) => e.id_incident === this.mission?.id && e.datatype === OVERLAY_TYPE.DRAWING);
		const visibleDrawingExists = drawings.some((drawing) => drawing.visible);
		if (!this.visibilityFilter.length) {
			this.visibilityFilter.push(new FilterGroup(FILTER_TYPE.POI, "P.O.I (" + this.text().EVENT + ", " + this.text().PREPLANNED + ")", true, true, false, this.conf.configuration.poiTypes, true));
			this.visibilityFilter.push(new FilterGroup(FILTER_TYPE.AREA, this.text().AREAS.toUpperCase() + this.text().AREA_VIEW_DETAILS, true, true, false, this.conf.configuration.areaTypes));
			this.visibilityFilter.push(new FilterGroup(FILTER_TYPE.KML, this.text().KML_INFORMATION.toUpperCase(), false, true, false, this.getMissionKMLs()));
			this.visibilityFilter.push(
				new FilterGroup(
					FILTER_TYPE.RESOURCES,
					this.text().RESOURCES.toUpperCase(),
					true,
					true,
					false,
					this.res.Resources.filter((resource) => resource.id_incident === this.mission!.id),
					true
				)
			);
			this.visibilityFilter.push(new FilterGroup(FILTER_TYPE.DRAWINGS, this.text().DRAWINGS.toUpperCase(), visibleDrawingExists, false, false, drawings));
			this.visibilityFilter.push(
				new FilterGroup(
					FILTER_TYPE.PLANES,
					this.text().PLANES.toUpperCase(),
					true,
					false,
					false,
					this.Overlays.filter((e) => e.datatype === OVERLAY_TYPE.SCHEMATIC)
				)
			);
			this.visibilityFilter.push(new FilterGroup(FILTER_TYPE.FSG, this.text().FSG_TITLE.toUpperCase(), true, false, false, this.fsg.Fsgs));
		} else {
			this.visibilityFilter[0].items = this.conf.configuration.poiTypes;
			this.visibilityFilter[1].items = this.conf.configuration.areaTypes;
			this.visibilityFilter[2].items = this.getMissionKMLs();
			this.visibilityFilter[3].items = this.res.Resources.filter((resource) => resource.id_incident === this.mission!.id);
			this.visibilityFilter[4].visible = visibleDrawingExists;
			this.visibilityFilter[4].items = drawings;
			this.visibilityFilter[5].items = this.Overlays.filter((e) => e.datatype === OVERLAY_TYPE.SCHEMATIC);
			this.visibilityFilter[6].items = this.fsg.Fsgs;
		}
		return this.visibilityFilter;
	};

	public distanceBetweenLatLngs(p1: google.maps.LatLng, p2: google.maps.LatLng): number {
		if (!p1 || !p2) {
			return 0;
		}

		const R = 6371 * 1000;
		const dLat = ((p2.lat() - p1.lat()) * Math.PI) / 180;
		const dLon = ((p2.lng() - p1.lng()) * Math.PI) / 180;
		const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos((p1.lat() * Math.PI) / 180) * Math.cos((p2.lat() * Math.PI) / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		const d = R * c;

		return d;
	}

	public distanceBetweenPoints(p1: WGSPoint, p2: WGSPoint): number {
		if (!p1 || !p2) {
			return 0;
		}

		const R = 6371;
		const dLat = ((p2.latitude - p1.latitude) * Math.PI) / 180;
		const dLon = ((p2.longitude - p1.longitude) * Math.PI) / 180;
		const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos((p1.latitude * Math.PI) / 180) * Math.cos((p2.latitude * Math.PI) / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		const d = R * c;

		return d;
	}

	public readonly getVisibilityFilter = (): FilterGroup[] => {
		return this.setFilter();
	};

	public readonly resetFilter: () => void = () => {
		this.visibilityFilter.forEach((filterType) => {
			if (filterType.tag === FILTER_TYPE.KML) return;
			filterType.items.forEach((item) => (item.visible = true));
			filterType.visible = true;
		});
	};

	public setHiddenState(isHidden: boolean): void {
		this.isHiddenSubject.next(isHidden);
	}

	public readonly getFileByName = (kml: Kml, gmap: google.maps.Map | undefined): void => {
		if (!this.kmlParser) this.kmlParser = new geoXML3.parser({ zoom: false, map: gmap, singleInfoWindow: true });
		if (!this.kmzParser) this.kmzParser = new geoXML3.parser({ isKml: false, zoom: false, map: gmap, singleInfoWindow: true });
		const ext = kml.attachment_filename.substring(kml.attachment_filename.length - 3);
		if (this.getKmlDataUrl.get(kml.id)) return this.showKml(kml);
		if (ext === "kmz") return this.parseKmz(kml);
		return this.parseKml(kml);
	};

	public readonly hideKml = (kml: Kml): void => {
		if (this.kmlParser && this.kmlParser.docs && this.kmlParser.docs.length) {
			const ext = kml.attachment_filename.substring(kml.attachment_filename.length - 3);
			if (ext === "kmz") {
				const idx = this.kmzParser.docs.findIndex((doc: any) => doc.url === this.getKmlDataUrl.get(kml.id));
				return this.kmzParser.hideDocument(this.kmzParser.docs[idx]);
			} else {
				const idx = this.kmlParser.docs.findIndex((doc: any) => doc.url === this.getKmlDataUrl.get(kml.id));
				this.kmlParser.hideDocument(this.kmlParser.docs[idx]);
			}
		}
	};

	public readonly showKml = (kml: Kml): void => {
		if (this.kmlParser && this.kmlParser.docs && this.kmlParser.docs.length) {
			const ext = kml.attachment_filename.substring(kml.attachment_filename.length - 3);
			if (ext === "kmz") {
				const idx = this.kmzParser.docs.findIndex((doc: any) => doc.url === this.getKmlDataUrl.get(kml.id));
				return this.kmzParser.showDocument(this.kmzParser.docs[idx]);
			} else {
				const idx = this.kmlParser.docs.findIndex((doc: any) => doc.url === this.getKmlDataUrl.get(kml.id));
				this.kmlParser.showDocument(this.kmlParser.docs[idx]);
			}
		}
	};

	public readonly getKmlBlobUrl = (kml: Kml): string => {
		const urlCreator = window.URL || window.webkitURL;
		return this.wreq.getFileByName(kml.attachment_filename).then((blob: Blob) => {
			const blobUrl = urlCreator.createObjectURL(blob);
			return blobUrl;
		});
	};

	public async attachToPoi(poiId: number, file: File): Promise<UploadedFile | undefined> {
		const ans = await this.wreq.attachPoiFile(poiId, file);
		if (!ans) return;
		const fans = UploadedFile.fromJson(ans);
		this.$poiAttachmentChange.next({
			type: "add",
			idPoi: poiId,
			idFile: fans.id
		});
		return fans;
	}

	public async deattachPoi(poiId: number, fileId: number): Promise<boolean> {
		const ans = await this.wreq.deattach("poi", poiId, fileId);
		if (ans) {
			this.$poiAttachmentChange.next({
				type: "remove",
				idPoi: poiId,
				idFile: fileId
			});
			return true;
		}
		return false;
	}

	// casts an array of json areas into their corresponding Area classes
	private readonly castJSONAreaArray = (array: Array<string>): Area[] => {
		let output: Array<Area> = [];
		array &&
			array.length &&
			array.forEach((areaJson: string) => {
				let areaType = Area.getTypeFromJson(areaJson);
				switch (areaType) {
					case SHAPE_TYPE.CIRCLE:
						output.push(CircleArea.fromJson(areaJson));
						break;
					case SHAPE_TYPE.POLYGON:
						output.push(PolygonArea.fromJson(areaJson));
						break;
					case SHAPE_TYPE.POLYLINE:
						output.push(PolygonArea.fromJson(areaJson));
						break;
					default:
						output.push(Area.fromJson(areaJson));
						break;
				}
			});
		return output;
	};

	private readonly getDistanceFromLatLonInKm: Function = (lat1: number, lon1: number, lat2: number, lon2: number) => {
		let R = 6371; // Radius of the earth in km
		let dLat = this.deg2rad(lat2 - lat1); // deg2rad below
		let dLon = this.deg2rad(lon2 - lon1);
		let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
		let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		let d = R * c * 1000; // Distance in m
		return d;
	};

	private readonly deg2rad: Function = (deg: number) => {
		return deg * (Math.PI / 180);
	};

	private readonly areCoordsInsideArea = (lat: number, lon: number, perimeter?: Area | undefined): boolean => {
		var maxLat = -9999,
			minLat = 9999,
			maxLng = -9999,
			minLng = 9999;
		let isInside = false;
		if (perimeter && perimeter.shapeType) {
			switch (perimeter.shapeType) {
				case SHAPE_TYPE.CIRCLE:
					const per = perimeter as CircleArea;
					let centerLat = per.center.latitude;
					let centerLon = per.center.longitude;
					minLat = centerLat - per.radius * 0.000008;
					maxLat = centerLat + per.radius * 0.000008;
					minLng = centerLon - per.radius * 0.000008;
					maxLng = centerLon + per.radius * 0.000008;
					break;
				case SHAPE_TYPE.POLYGON:
					const poly = perimeter as PolygonArea;
					for (var n = 0; n < poly.vertices.length; n++) {
						if (poly.vertices[n].latitude > maxLat) maxLat = poly.vertices[n].latitude;
						if (poly.vertices[n].latitude < minLat) minLat = poly.vertices[n].latitude;
						if (poly.vertices[n].longitude > maxLng) maxLng = poly.vertices[n].longitude;
						if (poly.vertices[n].longitude < minLng) minLng = poly.vertices[n].longitude;
					}
					break;
			}
			isInside = lat < maxLat && lat > minLat && lon < maxLng && lon > minLng;
		}
		return isInside;
	};

	private readonly filterKml = (kml: Kml): boolean => {
		return (this.mission && kml.id_incident == this.mission.id) || kml.id_incident == -1;
	};

	private readonly clearAll: Function = () => {
		this.POIs.length = 0;
		this.Areas.length = 0;
		this.POIsDB.length = 0;
		this.AreasDB.length = 0;
		this.arrayChanges.length = 0;
	};

	private readonly setupAttachments: Function = async () => {
		const rawData = await this.wreq.getAttachments(this.mission ? this.mission.id : -1, !!(this.mission && this.mission.closed));
		if (!rawData) return;
		DTOArray.UpdateFromJsonArray(this.attachments, rawData, UploadedFile);
		this.POIattachmentList = [];
		this.attachments.forEach((attachment: UploadedFile) => {
			var idx = this.POIs.findIndex((e) => e.id === attachment.id_object);
			if (idx > -1) {
				if (!this.POIattachmentList[idx]) this.POIattachmentList[idx] = [];
				this.POIattachmentList[idx].unshift(attachment);
			}
		});
		this.POIs.forEach((poi, i) => {
			if (!poi.attachmentList || poi.attachmentList.length == 0) {
				if (this.POIattachmentList[i]) poi.attachmentList = this.POIattachmentList[i];
			} else {
				if (this.POIattachmentList[i] && poi.attachmentList.length !== this.POIattachmentList[i].length) poi.attachmentList = this.POIattachmentList[i];
				else if (!this.POIattachmentList[i] || this.POIattachmentList[i].length == 0) poi.attachmentList = this.POIattachmentList[i];
			}
		});
		this.$poiAttachmentChange.next({
			type: "fullRefresh",
			idPoi: -1,
			idFile: -1
		});
		return true;
	};
	private readonly parseKml = (kml: Kml): void => {
		const urlCreator = window.URL || window.webkitURL;
		return this.wreq.getFileByName(kml.attachment_filename).then((blob: Blob) => {
			const blobUrl = urlCreator.createObjectURL(blob);
			this.getKmlDataUrl.set(kml.id, blobUrl);
			blob && this.kmlParser.parse(blobUrl);
		});
	};

	private readonly parseKmz = (kml: Kml): void => {
		const urlCreator = window.URL || window.webkitURL;
		return this.wreq.getFileByName(kml.attachment_filename).then((blob: Blob) => {
			const blobUrl = urlCreator.createObjectURL(blob);
			this.getKmlDataUrl.set(kml.id, blobUrl);
			blob && this.kmzParser.parse(blobUrl);
		});
	};

	private readonly changeExists: Function = (newChange: any) => {
		for (let i = 0; i < this.arrayChanges.length; i++) {
			let change = this.arrayChanges[i];
			if (change.type == newChange.type && change.elementId == newChange.elementId && change.oldValue == newChange.oldValue) return true;
		}
		return false;
	};

	private readonly refreshPrePlannedInfo: Function = async () => {
		const p1 = this.updatePois(true);
		const p2 = this.updateOverlays(false);
		const p3 = this.fsg.getFsg();
		await p1;
		await p2;
		await p3;
		this.itemsLoaded$.next();
	};
}

export class FilterGroup {
	tag: FILTER_TYPE = FILTER_TYPE.POI;
	title: string = "";
	visible: boolean = false;
	expandible: boolean = false;
	expanded: boolean = false;
	items: Array<AreaType | PoiType | Resource | Kml | Overlay | FsgBuilding>;
	hasSVG: boolean = false;

	constructor(tag: FILTER_TYPE, title: string, visible: boolean = true, expandible: boolean = false, expanded: boolean = false, items: Array<AreaType | PoiType | Resource | Kml | Overlay | FsgBuilding>, hasSVG: boolean = false) {
		this.tag = tag;
		this.title = title;
		this.visible = visible;
		this.expandible = expandible;
		this.expanded = expanded;
		this.items = items;
		this.hasSVG = hasSVG;
	}
}

export enum FILTER_TYPE {
	POI,
	AREA,
	KML,
	RESOURCES,
	DRAWINGS,
	PLANES,
	FSG
}

export interface POI_ATTACHMENT_CHANGE {
	type: "add" | "remove" | "fullRefresh";
	idPoi: number;
	idFile: number;
}
